// Core
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
// Instruments
import "./index.scss";

export default class Contact extends Component {
  render() {
    const column = this.props.column;
    const other_contacts = column.map(item => {
      if (item[0] !== "tel" && item[0] !== "email") {
        return (
          <FormattedMessage key={item[0]} id={item[1]}>
            {txt => {
              return <p className={`contact_${item[0]}`}>{txt}</p>;
            }}
          </FormattedMessage>
        );
      } else if (item[0] === "tel") {
        return (
          <p className={`contact_${item[0]}`} key={item[0]}>
            <a href={`tel:+${item[1][1]}`}>{item[1][0]}</a>
          </p>
        );
      } else if (item[0] === "email") {
        return (
          <p className={`contact_${item[0]}`} key={item[0]}>
            <a href={`mailto:${item[1]}`}>{item[1]}</a>
          </p>
        );
      } else {
        return <p></p>;
      }
    });
    return <div className="contact_container">{other_contacts}</div>;
  }
}
