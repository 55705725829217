// Core
import React, { Component } from "react";
import Slider from "react-slick";
import HeaderETS from "./headerETS";
import ContentBlock from "./contentBlock";
import Video from "../components/video";
import List from "../components/list";
import { FormattedMessage } from "react-intl";
import BackBtn from "./backBtn";
import { isMobileContext } from "../screens/App";
// Instruments
import "./index.scss";

export default class PopupSlider extends Component {
  state = {
    currentSlide: 1
  };

  _handleClose = name => {
    this.props.handleBtnMoreClick(name);
  };

  componentDidMount() {
    const { name } = this.props;
    const backBtn = document.querySelectorAll(".slick-prev");
    backBtn.forEach(el => {
      el.addEventListener("click", e => {
        if (el.classList.contains("slick-disabled")) {
          this._handleClose(name);
        } else {
          return false;
        }
      });
    });
  }

  render() {
    const { currentSlide } = this.state;
    const { isOpen, popupSlider, className } = this.props;
    const isActive = isOpen ? "active" : "";
    let settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      appendDots: function() {
        return (
          <div>
            {currentSlide}/{popupSlider.length}
          </div>
        );
      },
      afterChange: i => {
        this.setState({
          currentSlide: i + 1
        });
      }
    };
    return (
      <isMobileContext.Consumer>
        {isMobile => (
          <div
            className={`content_container slider slide_left_slider ${
              isMobile ? "mobile" : ""
            } ${isActive} ${className ? className : ""}`}
          >
            <div className="slider_wrapper">
              <Slider {...settings}>
                {popupSlider.map(slide => {
                  if ("video" in slide && slide.video === true) {
                    return (
                      <div key={slide.header} className="video_wrap">
                        <div className="main_container">
                          <FormattedMessage id={slide.header}>
                            {txt => {
                              return <h2>{txt}</h2>;
                            }}
                          </FormattedMessage>
                          <Video
                            url={
                              !isMobile ? slide.url_desctop : slide.url_mobile
                            }
                            poster = {
                              slide.url_poster
                            }
                          />
                        </div>
                        {popupSlider.length === 1 && (
                          <FormattedMessage id={"back.btn"}>
                            {txt => {
                              return <BackBtn text={txt} />;
                            }}
                          </FormattedMessage>
                        )}
                      </div>
                    );
                  } else if ("list" in slide) {
                    return (
                      <div key={slide.header}>
                        <div className="main_container main_container_popup">
                          <div className="content_wrapper">
                            <FormattedMessage id={slide.header}>
                              {txt => {
                                return <h2>{txt}</h2>;
                              }}
                            </FormattedMessage>
                            <List items={slide.list} />
                          </div>
                          <div className="image_wrapper">
                            <div className="image_container"></div>
                          </div>
                        </div>
                        {popupSlider.length === 1 && (
                          <FormattedMessage id={"back.btn"}>
                            {txt => {
                              return <BackBtn text={txt} />;
                            }}
                          </FormattedMessage>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <div key={slide.header}>
                        <div className="main_container">
                          <div className="content_wrapper">
                            <FormattedMessage id={slide.header}>
                              {txt => {
                                return <HeaderETS header={txt} />;
                              }}
                            </FormattedMessage>
                            <FormattedMessage id={slide.text}>
                              {txt => {
                                return (
                                  <ContentBlock
                                    className={`slide_left ${isActive}`}
                                    text={txt}
                                  />
                                );
                              }}
                            </FormattedMessage>
                          </div>
                          <div className="image_wrapper"></div>
                        </div>
                        {popupSlider.length === 1 && (
                          <FormattedMessage id={"back.btn"}>
                            {txt => {
                              return <BackBtn text={txt} />;
                            }}
                          </FormattedMessage>
                        )}
                      </div>
                    );
                  }
                })}
              </Slider>
            </div>
          </div>
        )}
      </isMobileContext.Consumer>
    );
  }
}
