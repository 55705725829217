import React, { Component } from "react";

import ReactScrollWheelHandler from "./scroll-wheel";
import { Responsive } from "semantic-ui-react";
import ScrollableAnchor from "react-scrollable-anchor";
import { configureAnchors } from "react-scrollable-anchor";

import { Swipeable } from "react-swipeable";
import Main from "./Main/index";
import Streams from "./Streams/index";
import IT from "./IT/index";
import Content from "./Content/index";
import Comercial from "./Comercial/index";
import AboutUs from "./AboutUs/index";
import Contacts from "./Contacts/index";
import ReactPageScroller from "react-page-scroller";
import { IntlProvider } from "react-intl";
import { ClickMenuContext } from "./Main/clickMenuContext";
import { messages } from "../locale/messages";
import { invert } from "lodash";

export const LanguageContext = React.createContext();
export const isMobileContext = React.createContext();

const DIRECTIONS = ["Left", "Right", "Up", "Down"];
const persistSyntheticEvent = (func, persist) => {
  return (e, ...rest) => {
    if (e.dir === "Left" || e.dir === "Right") {
      e.event.preventDefault();
      e.event.stopImmediatePropagation();
    }
    if (persist && e.persist) e.persist();
    return func(e, ...rest);
  };
};
function getBoundSwipes(component) {
  const { persistEvent } = component.state;
  let boundSwipes = {};
  DIRECTIONS.forEach(dir => {
    if (component.state[`onSwiped${dir}Applied`]) {
      boundSwipes[`onSwiped${dir}`] = persistSyntheticEvent(
        component.onSwipedDirection.bind(component, dir),
        persistEvent
      );
    }
  });
  return boundSwipes;
}

const hashToPage = {
  "#streams": 2,
  "#it": 3,
  "#content": 4,
  "#commercial": 5,
  "#about": 6,
  "#contacts": 7
};

configureAnchors({
  offset: -150
});
class App extends Component {
  constructor(props) {
    super(props);
    this.rootRef = React.createRef();

    this.state = {
      currentPage: 1,
      handleClickMenu: this.goToPage,
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
      onSwipingApplied: true,
      swipingEvent: null,
      onSwipedApplied: true,
      preventDefaultTouchmoveEvent: false,
      trackTouch: true,
      trackMouse: false,
      persistEvent: true,
      swiping: false,
      isSliderVisible: false,
      stopKey: false,
      swiped: false,
      block: false,
      itCallback: null,
      scrollDirection: "",
      names: [],
      isMobile: false,
      hash: ""
    };
    this._pageScroller = null;
  }

  setPopUp = e => {
    if (e) {
      this.popUpIndex = e;
    }
  };

  handleBtnPopup = (callback, names) => {
    this.setState({
      itCallback: callback,
      names: names
    });
  };
  popUpIndex = -1;
  onSwiped(args) {
    this.setState({
      swiped: true,
      swiping: false
    });
    const {
      itCallback,
      names,
      swiped,
      swipingDirection,
      currentPage
    } = this.state;

    if (currentPage === 3) {
      args.event.stopImmediatePropagation();
      args.event.preventDefault();
      if (swipingDirection === "Up" && swiped) {
        this.popUpIndex++;
        if (this.popUpIndex === names.length) {
          this.popUpIndex++;
        }
      }
      if (swipingDirection === "Down" && swiped) {
        if (this.popUpIndex > -2) {
          this.popUpIndex--;
        }
      }
      itCallback(names[this.popUpIndex]);
    }
  }

  onSwiping(args) {
    const { names, currentPage } = this.state;

    if (currentPage === 3) {
      if (this.popUpIndex <= names.length) {
        if (args.dir === "Up") {
          args.event.preventDefault();
          args.event.stopImmediatePropagation();
        }
        if (
          args.dir === "Down" &&
          (this.popUpIndex === -2 || this.popUpIndex === -1)
        ) {
          return true;
        }
        if (args.dir === "Down" && this.popUpIndex !== -2) {
          args.event.preventDefault();
          args.event.stopImmediatePropagation();
        }
      }
    } else {
      this.popUpIndex = -2;
    }
    this.setState({
      swiping: true,
      swiped: false,
      swipingDirection: args.dir
    });
  }

  goToPage = eventKey => {
    if (!this.state.isMobile) {
      if (eventKey === 7) {
        this.props.signOut();
        return;
      }
      this._pageScroller.goToPage(eventKey);
    } else {
      window.location.hash = invert(hashToPage)[eventKey + 1];
    }
  };
  pageOnChange = (number, ev) => {
    const { currentPage, scrollDirection, names } = this.state;
    if (currentPage !== 3) {
      if (this.popUpIndex <= names.length && scrollDirection === "down") {
        this.setState({ stopKey: true });
      } else if (this.popUpIndex !== -1 && scrollDirection === "up") {
        this.setState({ stopKey: true });
      } else {
        this.setState({ stopKey: false });
      }
    }
    this.setState({ currentPage: number });
  };

  changeLanguage = (ev, data) => {
    this.setState({ lang: data.value });
    localStorage.setItem("lang", data.value);
    /* change html lang attribute */
    document.documentElement.lang = data.value;
  };

  hashChangeHandler = e => {
    this.setState({ hash: window.location.hash });
    if (window.location.hash) {
      this.setState({ currentPage: hashToPage[window.location.hash] });
    } else {
      this.setState({ currentPage: 1 });
    }
  };

  componentDidMount() {
    window.addEventListener("hashchange", this.hashChangeHandler);
  }
  stopScrollingBody = bool => {
    if (bool === true) {
      document.addEventListener("touchmove", this.freezeVp, { passive: false });
    } else {
      document.removeEventListener("touchmove", this.freezeVp, {
        passive: false
      });
    }
  };
  freezeVp = e => {
    e.preventDefault();
  };

  getIsSliderVisible = value => {
    const body = document.getElementById("body");
    if (value) {
      body.style = "overflow:hidden;";
      this.stopScrollingBody(true);
    } else {
      body.style = "overflow:auto;";
      this.stopScrollingBody(false);
    }
    this.setState({ isSliderVisible: value });
  };

  // ----------------------------------------------------------------
  // upHandler
  upHandler = e => {
    const { itCallback, currentPage, names, isSliderVisible } = this.state;

    let pageTogo = currentPage;
    if (!isSliderVisible) {
      pageTogo -= 2;

      this.setState({ scrollDirection: "up" });
      if (currentPage === 3) {
        if (this.popUpIndex === -1 || this.popUpIndex >= names.length) {
          this.popUpIndex = -1;
          itCallback(names[this.popUpIndex]);
          this._pageScroller.goToPage(pageTogo);
          return;
        } else {
          this.popUpIndex--;

          itCallback(names[this.popUpIndex]);
        }
      } else {
        this.popUpIndex = -1;
        this._pageScroller.goToPage(pageTogo);
      }
    }
  };
  // --------------------------------------
  // Down Handler
  downHandler = e => {
    const {
      itCallback,
      currentPage,
      names,
      isSliderVisible
      // pageTogo
    } = this.state;
    let pageTogo;

    pageTogo = currentPage;

    if (!isSliderVisible) {
      this.setState({ scrollDirection: "down" });

      if (currentPage === 3) {
        if (this.popUpIndex >= names.length) {
          this.popUpIndex = -1;
          itCallback(names[this.popUpIndex]);

          this._pageScroller.goToPage(pageTogo);

          return true;
        } else {
          this.popUpIndex += 1;
          itCallback(names[this.popUpIndex]);
        }
      } else {
        this.popUpIndex = -1;

        this._pageScroller.goToPage(pageTogo);

        pageTogo += 1;
      }
    }
  };

  render() {
    document.documentElement.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    const {
      lang,
      onSwipingApplied,
      onSwipedApplied,
      swipingDirection,
      swipingEvent,
      isSliderVisible,
      preventDefaultTouchmoveEvent,
      trackTouch,
      trackMouse,
      hash,
      stopKey,
      scrollDirection,
      persistEvent,
      isMobile
    } = this.state;

    const boundSwipes = getBoundSwipes(this);

    let swipeableDirProps = {};
    if (onSwipingApplied) {
      swipeableDirProps.onSwiping = persistSyntheticEvent(
        (...args) => this.onSwiping(...args),
        persistEvent
      );
    }
    if (onSwipedApplied) {
      swipeableDirProps.onSwiped = persistSyntheticEvent(
        (...args) => this.onSwiped(...args),
        persistEvent
      );
    }
    return (
      <Responsive
        fireOnMount={true}
        onUpdate={(e, args) => {
          this.setState({ isMobile: args.getWidth() <= 960 });
        }}
      >
        {!isMobile ? (
          <ReactScrollWheelHandler
            upHandler={this.upHandler}
            downHandler={this.downHandler}
          >
            <IntlProvider locale={lang} messages={messages[lang]}>
              <ReactPageScroller
                className="scroller"
                ref={c => (this._pageScroller = c)}
                pageOnChange={this.pageOnChange}
                blockScrollUp={isSliderVisible || stopKey}
                blockScrollDown={isSliderVisible || stopKey}
              >
                <Swipeable
                  className="swipeable_block"
                  {...boundSwipes}
                  {...swipeableDirProps}
                  preventDefaultTouchmoveEvent={preventDefaultTouchmoveEvent}
                  trackTouch={trackTouch}
                  trackMouse={trackMouse}
                >
                  <LanguageContext.Provider
                    value={{ changeLanguage: this.changeLanguage, lang }}
                  >
                    <ClickMenuContext.Provider value={this.state}>
                      <Main
                        currentPage={this.state.currentPage}
                        swipeDirection={swipingDirection}
                        swipingEvent={swipingEvent}
                      />
                    </ClickMenuContext.Provider>
                  </LanguageContext.Provider>
                </Swipeable>
                <Swipeable
                  className="swipeable_block"
                  {...boundSwipes}
                  {...swipeableDirProps}
                  preventDefaultTouchmoveEvent={preventDefaultTouchmoveEvent}
                  trackTouch={trackTouch}
                  trackMouse={trackMouse}
                >
                  <Streams currentPage={this.state.currentPage} />
                </Swipeable>
                <Swipeable
                  className="swipeable_block"
                  {...boundSwipes}
                  {...swipeableDirProps}
                  preventDefaultTouchmoveEvent={preventDefaultTouchmoveEvent}
                  trackTouch={trackTouch}
                  trackMouse={trackMouse}
                >
                  <IT
                    currentPage={this.state.currentPage}
                    setPopUp={this.setPopUp}
                    scrollDirection={scrollDirection}
                    handleBtnPopup={this.handleBtnPopup}
                    getIsSliderVisible={this.getIsSliderVisible}
                  />
                </Swipeable>
                <Swipeable
                  className="swipeable_block"
                  {...boundSwipes}
                  {...swipeableDirProps}
                  preventDefaultTouchmoveEvent={preventDefaultTouchmoveEvent}
                  trackTouch={trackTouch}
                  trackMouse={trackMouse}
                >
                  <Content
                    currentPage={this.state.currentPage}
                    getIsSliderVisible={this.getIsSliderVisible}
                  />
                </Swipeable>
                <Swipeable
                  className="swipeable_block"
                  {...boundSwipes}
                  {...swipeableDirProps}
                  preventDefaultTouchmoveEvent={preventDefaultTouchmoveEvent}
                  trackTouch={trackTouch}
                  trackMouse={trackMouse}
                >
                  <Comercial currentPage={this.state.currentPage} />
                </Swipeable>
                <Swipeable
                  className="swipeable_block"
                  {...boundSwipes}
                  {...swipeableDirProps}
                  preventDefaultTouchmoveEvent={preventDefaultTouchmoveEvent}
                  trackTouch={trackTouch}
                  trackMouse={trackMouse}
                >
                  <AboutUs currentPage={this.state.currentPage} />
                </Swipeable>
                <Swipeable
                  className="swipeable_block"
                  {...boundSwipes}
                  {...swipeableDirProps}
                  preventDefaultTouchmoveEvent={preventDefaultTouchmoveEvent}
                  trackTouch={trackTouch}
                  trackMouse={trackMouse}
                >
                  <Contacts currentPage={this.state.currentPage} />
                </Swipeable>
              </ReactPageScroller>
            </IntlProvider>
          </ReactScrollWheelHandler>
        ) : (
          <IntlProvider locale={lang} messages={messages[lang]}>
            <isMobileContext.Provider value={this.state.isMobile}>
              <LanguageContext.Provider
                value={{ changeLanguage: this.changeLanguage, lang }}
              >
                <ClickMenuContext.Provider value={this.state}>
                  <Main currentPage={this.state.currentPage} />
                </ClickMenuContext.Provider>
              </LanguageContext.Provider>
              <ScrollableAnchor id={"streams"}>
                <Streams
                  currentPage={this.state.currentPage}
                  isMobile={isMobile}
                />
              </ScrollableAnchor>
              <ScrollableAnchor id={"it"}>
                <IT
                  currentPage={this.state.currentPage}
                  setPopUp={this.setPopUp}
                  handleBtnPopup={this.handleBtnPopup}
                  isMobile={isMobile}
                  hash={hash}
                  getIsSliderVisible={this.getIsSliderVisible}
                />
              </ScrollableAnchor>
              <ScrollableAnchor id={"content"}>
                <Content
                  currentPage={this.state.currentPage}
                  getIsSliderVisible={this.getIsSliderVisible}
                />
              </ScrollableAnchor>
              <ScrollableAnchor id={"commercial"}>
                <Comercial currentPage={this.state.currentPage} />
              </ScrollableAnchor>
              <ScrollableAnchor id={"about"}>
                <AboutUs currentPage={this.state.currentPage} />
              </ScrollableAnchor>
              <ScrollableAnchor id={"contacts"}>
                <Contacts currentPage={this.state.currentPage} />
              </ScrollableAnchor>
            </isMobileContext.Provider>
          </IntlProvider>
        )}
      </Responsive>
    );
  }
}

export default App;
