import React, { Component } from "react";
import "./index.scss";
import HeaderETS from "../../components/headerETS";
import ContentBlock from "../../components/contentBlock";
import IconsBlock from "../../components/iconsBlock";
import MoreBtn from "../../components/moreBtn";
import PopupSlider from "../../components/popupSlider";
import { FormattedMessage } from "react-intl";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobileContext } from "../App";
import Content_img from "../../assets/Content.png";
import Content_path from "../../assets/Content_path.png";

export default class Content extends Component {
  state = {
    page: 4,
    icons: ["hotel", "flights", "railway", "car"],
    isOpen: false,
    openSlider: null,
    popupSlider: [
      {
        header: "Content.slide1.header",
        text: "Content.slide1.text",
        list: [
          "item1",
          "item2",
          "item3",
          "item4",
          "item5",
          "item6",
          "item7",
          "item8"
        ]
      }
    ]
  };
  _handleBtnMoreClick = name => {
    if (name === this.state.openSlider) {
      this.props.getIsSliderVisible(!this.state.isOpen);
      this.setState({
        openSlider: null,
        isOpen: !this.state.isOpen
      });
    } else if (this.props.currentPage === 4) {
      this.props.getIsSliderVisible(!this.state.isOpen);
      this.setState({
        openSlider: name,
        isOpen: !this.state.isOpen
      });
    }
  };

  getAnimatedContent = (txt, isActive) => {
    return (
      <isMobileContext.Consumer>
        {isMobile =>
          isMobile ? (
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <ContentBlock text={txt} />
            </ScrollAnimation>
          ) : (
            <ContentBlock className={`slide_up ${isActive}`} text={txt} />
          )
        }
      </isMobileContext.Consumer>
    );
  };

  getMoreButton = (txt, isActive, cb) => {
    return (
      <isMobileContext.Consumer>
        {isMobile =>
          isMobile ? (
            <ScrollAnimation
              animateIn="slideInLeft"
              delay={600}
              animateOnce={true}
            >
              <MoreBtn
                isActive={"active"}
                text={txt}
                name="Content"
                onClick={cb}
              />
            </ScrollAnimation>
          ) : (
            <MoreBtn
              isActive={isActive}
              text={txt}
              name="Content"
              onClick={cb}
            />
          )
        }
      </isMobileContext.Consumer>
    );
  };

  render() {
    const { page, icons, popupSlider, isOpen, openSlider } = this.state;
    const isActive = page === this.props.currentPage ? "active" : "";
    return (
      <div className="wrapper_container">
        <div
          className={`content_container content_with_slider ${
            isOpen ? "slide_left_slide" : "slide_right_slide"
          } ${isActive}`}
        >
          <div className="main_container main_container_slide">
            <isMobileContext.Consumer>
              {isMobile =>
                isMobile ? (
                  <div className="image_wrapper image_wrapper_content">
                    <img
                      src={Content_path}
                      className="content_path"
                      alt="content"
                    />

                    <ScrollAnimation
                      animateIn="fadeIn"
                      animateOnce={true}
                      delay={200}
                      duration={1.1}
                    >
                      <img
                        src={Content_img}
                        className="content_img"
                        alt="content"
                      />
                    </ScrollAnimation>
                  </div>
                ) : null
              }
            </isMobileContext.Consumer>
            <div className="content_wrapper">
              <FormattedMessage id={"Content.h2"}>
                {txt => {
                  return <HeaderETS header={txt} />;
                }}
              </FormattedMessage>
              <FormattedMessage id={"Content.text"}>
                {txt => this.getAnimatedContent(txt, isActive)}
              </FormattedMessage>

              <IconsBlock
                icons={icons}
                isActive={isActive}
                animation="slide_up_icons"
              />
              <FormattedMessage id={"learn more.btn"}>
                {txt =>
                  this.getMoreButton(txt, isActive, this._handleBtnMoreClick)
                }
              </FormattedMessage>
            </div>

            <div className="image_wrapper"></div>
          </div>
        </div>
        <PopupSlider
          className="content_slider"
          handleBtnMoreClick={this._handleBtnMoreClick}
          isOpen={openSlider === "Content"}
          popupSlider={popupSlider}
          name="Content"
        />
      </div>
    );
  }
}
