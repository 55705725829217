// Core
import React, { Component } from 'react';

// Instruments
import "./index.scss";

export default class PopupBtn extends Component {
  render() {
    const { text, onClick, name, propRef } = this.props;
    return (
        <div className={`popup_btn  ${text}`}
              name ={name}
              ref={propRef ? propRef: null }
             onClick= {() => onClick(name)}>
          <div className="popup_btn_text">{text}</div>
          <div className="popup_btn_img"></div>
        </div>
    );
  }
}
