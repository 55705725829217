import React, { Component } from "react";

import "./index.scss";
import HeaderETS from "../../components/headerETS";
import ContentBlock from "../../components/contentBlock";
import { FormattedMessage } from "react-intl";
import PopupBtn from "../../components/popupBtn";
import PopupSlide from "../../components/popupSlide";
import PopupSlider from "../../components/popupSlider";
import video_poster from "../../assets/ETS_Packshot.png";
import video_desctop from "../../assets/ETS_Video1130x810.mp4";
import video_mobile from "../../assets/ETS_Video250x310.mp4";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobileContext } from "../App";
import IT_img from "../../assets/IT.png";
import IT_path from "../../assets/IT_path2.png";

const buttons = [
  [{ name: "Business", index: 0 }, { name: "Online", index: 1 }],
  [{ name: "Corporate", index: 2 }, { name: "MOS", index: 3 }]
];
const popups = [
  {
    popup: [
      {
        header: "IT.Business.slide1.header",
        video: true,
        url_desctop: video_desctop,
        //url_mobile: video_mobile,
        url_mobile: video_desctop,
        url_poster: video_poster
      }//,
      //{
        //header: "IT.Business.slide2.header",
        //text: "IT.Business.slide2.text"
      //}
    ],
    name: "Business"
  },
  {
    popup: [
      {
        header: "IT.Online.slide1.header",
        text: "IT.Online.slide1.text"
      }
    ],
    name: "Online"
  },
  {
    popup: [
      {
        header: "IT.Corporate.slide1.header",
        text: "IT.Corporate.slide1.text"
      }
    ],
    name: "Corporate"
  },
  {
    popup: [
      {
        header: "IT.MOS.slide1.header",
        text: "IT.MOS.slide1.text"
      }
    ],
    name: "MOS"
  }
];

export default class IT extends Component {
  constructor(props) {
    super(props);
    this.btnRef_Business = React.createRef();
    this.btnContainer = React.createRef();
    this.btnImgContainer = React.createRef();

    this._isMounted = false;
  }

  state = {
    page: 3,
    names: ["Business", "Online", "Corporate", "MOS"],
    timer: null,
    value: true,
    openPopup: null,
    animationFinished: true,
    openSlider: null,
    isActive: "",
    icons: ["web", "mobile", "api"],
    isSliderVisible: false,
    isClick: false
  };

  UNSAFE_componentWillReceiveProps(props, state) {
    !this.state.isActive &&
      this.setState({
        isActive: this.state.page === props.currentPage ? "active" : ""
      });
  }

  componentWillUnmount() {}
  componentDidMount() {
    this._isMounted = true;

    this.props.handleBtnPopup(this._handleBtnPopupClick, this.state.names);
  }

  listenerClick = e => {
    this._handleBtnPopupClick(e);
  };

  stopScrollingBody = bool => {
    if (bool === true) {
      document.addEventListener("touchmove", this.freezeVp, { passive: false });
    } else {
      document.removeEventListener("touchmove", this.freezeVp, {
        passive: false
      });
    }
  };
  freezeVp = e => {
    e.preventDefault();
  };
  _handleBtnPopupClick = (name, popUpIndex) => {
    const body = document.getElementById("body");
    if (name || this.state.openSlider) {
      body.style = "overflow:hidden; ";
      this.stopScrollingBody(true);
    }
    if (this.state.openSlider) {
      return;
    }

    this.props.setPopUp(popUpIndex);
    if (popUpIndex !== undefined) {
      this.setState({
        isClick: true
      });
    } else {
      this.setState({
        isClick: false
      });
    }

    this.setState(prevState => {
      return {
        openPopup: name,
        anotherDelayToBlue:
          this.props.scrollDirection === "up" &&
          prevState.openPopup === "Online"
      };
    });
  };
  _handleBtnMoreClick = name => {
    if (name === this.state.openSlider) {
      this.props.getIsSliderVisible(!this.state.isSliderVisible);

      this.setState({
        openSlider: null,
        isSliderVisible: !this.state.isSliderVisible
      });
    } else if (this.state.openPopup === name) {
      this.props.getIsSliderVisible(!this.state.isSliderVisible);
      this.setState({
        openSlider: name,
        isSliderVisible: !this.state.isSliderVisible
      });
    }
  };

  _handleClose = () => {
    const body = document.getElementById("body");
    this.setState({
      isClick: false,
      openPopup: null
    });
    body.style = "overflow:auto; ";
    this.stopScrollingBody(false);
    this.props.setPopUp(5);
  };

  getAnimatedContent = (txt, isActive) => {
    return (
      <isMobileContext.Consumer>
        {isMobile =>
          isMobile ? (
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <ContentBlock text={txt} />
            </ScrollAnimation>
          ) : (
            <ContentBlock className={`slide_up ${isActive}`} text={txt} />
          )
        }
      </isMobileContext.Consumer>
    );
  };

  getBtnsAnimated = isActive => {
    return (
      <isMobileContext.Consumer>
        {isMobile =>
          isMobile ? (
            <ScrollAnimation
              animateIn="popupBtnGroup active"
              animateOnce={true}
              animateOut="popupBtnGroup active"
            >
              {this.getBtnsJsx()}
            </ScrollAnimation>
          ) : (
            <div className={`popupBtnGroup ${isActive}`}>
              {this.getBtnsJsx()}
            </div>
          )
        }
      </isMobileContext.Consumer>
    );
  };

  getBtnsJsx = () => {
    return (
      <div className="row">
        {buttons.map((arr, index) => {
          return (
            <div className="column" key={`column-${index}`}>
              {arr.map(button => (
                <FormattedMessage
                  id={`IT.${button.name}.btn`}
                  key={button.name}
                >
                  {txt => {
                    return (
                      <PopupBtn
                        propRef={el =>
                          button.name === "Business"
                            ? (this.btnRef_Business = el)
                            : false
                        }
                        name={button.name}
                        text={txt}
                        onClick={name =>
                          this._handleBtnPopupClick(name, button.index)
                        }
                      />
                    );
                  }}
                </FormattedMessage>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      openPopup,
      openSlider,
      isSliderVisible,
      isClick,
      isActive,
      anotherDelayToBlue,
      icons
    } = this.state;

    const position = {
      Business: {
        left: isActive
          ? this.btnRef_Business.getBoundingClientRect().x - 20
          : null,
        top: isActive
          ? this.btnContainer.current.offsetTop +
            this.btnImgContainer.current.offsetTop -
            20
          : null
      },
      Online: {
        left: isActive
          ? this.btnRef_Business.getBoundingClientRect().x - 20
          : null,
        top: isActive
          ? this.btnContainer.current.offsetTop +
            this.btnImgContainer.current.offsetTop +
            70
          : null
      },
      Corporate: {
        left: isActive
          ? this.btnRef_Business.getBoundingClientRect().x + 210
          : null,
        top: isActive
          ? this.btnContainer.current.offsetTop +
            this.btnImgContainer.current.offsetTop -
            20
          : null
      },
      MOS: {
        left: isActive
          ? this.btnRef_Business.getBoundingClientRect().x + 210
          : null,
        top: isActive
          ? this.btnContainer.current.offsetTop +
            this.btnImgContainer.current.offsetTop +
            70
          : null
      }
    };

    return (
      <div id="IT" className="wrapper_container IT">
        <div className="content_container">
          <div
            className="main_container main_container_slide main_container_IT"
            ref={this.btnContainer}
          >
            <div className="content_wrapper">
              <FormattedMessage id={"IT.h2"}>
                {txt => {
                  return <HeaderETS header={txt} />;
                }}
              </FormattedMessage>
              <FormattedMessage id={"IT.text"}>
                {txt => this.getAnimatedContent(txt, isActive)}
              </FormattedMessage>
            </div>
            <div
              className="image_wrapper image_wrapper_it"
              ref={this.btnImgContainer}
            >
              {this.getBtnsAnimated(isActive)}

              <isMobileContext.Consumer>
                {isMobile =>
                  isMobile ? (
                    <div className="image_wrapper_it_container">
                      <img src={IT_path} className="it_path" alt="IT" />
                      <ScrollAnimation
                        animateIn="fadeIn"
                        animateOnce={true}
                        delay={200}
                        offset={0}
                        duration={1.1}
                      >
                        <img src={IT_img} className="it" alt="IT" />
                      </ScrollAnimation>
                    </div>
                  ) : null
                }
              </isMobileContext.Consumer>
            </div>
          </div>
        </div>

        {popups.map((item, index) => (
          <div key={item.name + index}>
            <PopupSlide
              name={item.name}
              header={`IT.${item.name}.header`}
              text={`IT.${item.name}.text`}
              isOpen={openPopup === item.name}
              positionLeft={position[item.name].left}
              positionTop={position[item.name].top}
              icons={item.name == "Online" ? icons : false}
              onClickFunc={this._handleBtnMoreClick}
              isSliderVisible={isSliderVisible}
              isClick={isClick}
              anotherDelayToBlue={anotherDelayToBlue}
              closeFunc={this._handleClose}
            />
            <PopupSlider
              handleBtnMoreClick={this._handleBtnMoreClick}
              isOpen={openSlider === item.name}
              popupSlider={item.popup}
              name={item.name}
            />
          </div>
        ))}
      </div>
    );
  }
}
