// Core
import React, { Component } from "react";

// Instruments
import "./index.scss";

export default class Close extends Component {
  render() {
     const { onClick } = this.props
    return (
        <div className='close_btn' onClick={onClick}></div>
    );
  }
}