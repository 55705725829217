import React from "react";

export const Logo = props => {
  //   const [inProp, setInProp] = useState(false);

  return (
    <div className="header_logo">
      <img src={props.logo} className="logo" alt="logo" />
    </div>
  );
};
