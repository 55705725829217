// Core
import React, { Component } from "react";

// Instruments
import "./index.scss";

export default class BackBtn extends Component {
  render() {
    const { text} = this.props;
    return (
        <div className='back_btn slick-prev slick-disabled'>
           <div className="back_btn_img"></div>
          <div className="back_btn_text">{text}</div>
         
        </div>
    );
  }
}