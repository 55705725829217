// Core
import React, { Component } from "react";
import Icon from "./icon";
// Instruments
import "./index.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobileContext } from "../screens/App";

export default class IconsBlock extends Component {
  getIconsJsx = (icons, isActive, animation) => {
    return (
      <isMobileContext.Consumer>
        {isMobile =>
          isMobile && animation!=='slide_left_icons'
            ? icons.map((icon, i) => {
                return (
                  <ScrollAnimation
                    key={`${icon}-${i}`}
                    animateIn="fadeInUp"
                    animateOnce={true}
                    delay={i * 200 + 400}
                  >
                    <Icon name={icon} />
                  </ScrollAnimation>
                );
              })
            : icons.map((icon, i) => {
                return (
                  <Icon
                    key={`${icon}-${i}-1`}
                    name={icon}
                    delay={i * 200 + 400}
                    isActive={isActive}
                    animation={animation}
                  />
                );
              })
        }
      </isMobileContext.Consumer>
    );
  };
  render() {
    const { icons, isActive, animation } = this.props;
    // let iconJSX = icons.map((icon, i) => {
    //   return (
    //     <Icon
    //       key={icon}
    //       name={icon}
    //       delay={i * 200 + 400}
    //       isActive={isActive}
    //       animation={animation}
    //     />
    //   );
    // });
    return (
      <div className="icons_wrapper">
        {this.getIconsJsx(icons, isActive, animation)}
      </div>
    );
  }
}
