import React from "react";

import { ReactComponent as Content } from "../../../assets/Content.svg";
export const ContentItem = props => {
  return (
    <div className={`cube_item content_item ${props.className.join(" ")}`}>
      <Content />
    </div>
  );
};
