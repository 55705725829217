import React, { Component } from "react";
import "./index.scss";
import Contact from "../../components/contact";
// import Clock from "../../components/clock";
import { FormattedMessage } from "react-intl";

export default class Contacts extends Component {
  state = {
    // date: new Date(),
    sales: [
      ["title", "Contact.sales.title"],
      ["tel", ["+7 (495) 204 27 83", 74952042783]],
      ["email", "sales@etm-system.com"],
    ],
    support: [
      ["title", "Contact.support.title"],
      ["tel", ["+7 (495) 204 27 83", 74952042783]],
      ["email", "support@etm-system.com"],
    ],
    mainoffice: [
      ["title", "Contact.mainoffice.title"],
      ["tel", ["+49 (0) 911 237 749 38", 49091123774938]],
      ["email", "info@etm-system.com"],
      ["company", "Contact.mainoffice.company"],
      ["city", "Contact.mainoffice.city"],
      ["adress", "Contact.mainoffice.adress"],
     
    ]
  };
  // componentDidMount() {
  //   this.timerID = setInterval(() => this.tick(), 1000);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.timerID);
  // }

  // tick() {
  //   this.setState({
  //     date: new Date()
  //   });
  // }
  render() {
    const { sales, support, mainoffice} = this.state;
    // const currentDate1 = new Date(
    //   date.toLocaleString("en-US", { timeZone: "Europe/Kiev" })
    // ).toLocaleTimeString(navigator.language, {
    //   hour: "2-digit",
    //   minute: "2-digit"
    // });
    // const currentDate2 = new Date(
    //   date.toLocaleString("en-US", { timeZone: "Europe/Kaliningrad" })
    // ).toLocaleTimeString(navigator.language, {
    //   hour: "2-digit",
    //   minute: "2-digit"
    // });
    // const currentDate3 = new Date(
    //   date.toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    // ).toLocaleTimeString(navigator.language, {
    //   hour: "2-digit",
    //   minute: "2-digit"
    // });
    // const hours1 =
    //   (((new Date(
    //     date.toLocaleString("en-US", { timeZone: "Europe/Kiev" })
    //   ).getHours() +
    //     11) %
    //     12) +
    //     1) *
    //   30;
    // const minutes1 =
    //   new Date(
    //     date.toLocaleString("en-US", { timeZone: "Europe/Kiev" })
    //   ).getMinutes() * 6;
    // const hours2 =
    //   (((new Date(
    //     date.toLocaleString("en-US", { timeZone: "Europe/Kaliningrad" })
    //   ).getHours() +
    //     11) %
    //     12) +
    //     1) *
    //   30;
    // const minutes2 =
    //   new Date(
    //     date.toLocaleString("en-US", { timeZone: "Europe/Kaliningrad" })
    //   ).getMinutes() * 6;
    // const hours3 =
    //   (((new Date(
    //     date.toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    //   ).getHours() +
    //     11) %
    //     12) +
    //     1) *
    //   30;
    // const minutes3 =
    //   new Date(
    //     date.toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    //   ).getMinutes() * 6;
    return (
      <div className="wrapper_container contacts">
        <div className="content_container contacts">
          <div className="main_container main_container_slide">
            <div className="content_wrapper">
            <FormattedMessage id={'Contact.h2'}>
                    {txt => {
                      return (
                        <h2>{txt}</h2>
                      );
                    }}
              </FormattedMessage>
           
              <div className="contacts_wrapper">
                <div className="contacts_item">
                  {/* <Clock
                    date={currentDate1}
                    hours={hours1}
                    minutes={minutes1}
                  /> */}
                  <Contact column={sales} />
                </div>
                <div className="contacts_item">
                  {/* <Clock
                    date={currentDate2}
                    hours={hours2}
                    minutes={minutes2}
                  /> */}
                  <Contact column={support} />
                </div>
                <div className="contacts_item">
                  {/* <Clock
                    date={currentDate3}
                    hours={hours3}
                    minutes={minutes3}
                  /> */}
                  <Contact column={mainoffice} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
