import React from "react";
import { Responsive } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import ScrollAnimation from "react-animate-on-scroll";
export const MainText = () => {
  return (
    <Responsive
      className="content_container content_container_main"
      minWidth={1024}
      fireOnMount={true}
    >
      <div className="main_container">
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <FormattedMessage id={"main.h1"}>
            {txt => {
              return <h1>{txt}</h1>;
            }}
          </FormattedMessage>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={500} animateOnce={true}>
          <FormattedMessage id={"main.text"}>
            {txt => {
              return <div className={`main_content `}>{txt}</div>;
            }}
          </FormattedMessage>
        </ScrollAnimation>
      </div>
    </Responsive>
  );
};
