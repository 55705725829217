import { EventEmitter } from "events";
export default class CubeAnimation extends EventEmitter {
  constructor(state) {
    super();
    this.animationClasses = {
      main: ["cube_wrapper"],
      cube: [],
      it: [],
      content: [],
      commercial: [],
      shadow: []
    };
    this.transformCube = {
      x: 150,
      y: 0,
      z: 0
    };

    this.init(state);

    this.scaleCube = 1;
    if (window.innerWidth <= 961) {
      this.transformCube.y = window.innerWidth / 2;
      this.emit("cubeRefresh", this.getterTransform);
    }
  }

  set _currentPage(value) {
    this.currentPage = value;

    this.refreshCube(value);
  }
  get _currentPage() {
    return this.currentPage;
  }
  get getterTransform() {
    const { x, y, z } = this.transformCube;
    return `${x}px, ${y}px, ${z}px`;
  }

  init(state) {
    this.cube = state.cube;
    this.container = state.container;
    this.windowWidth = state.windowWidth;
    this.windowHeight = state.windowHeight;
  }
  refreshCube(value) {
    let {
      animationClasses,
      cube,
      container,
      windowWidth,
      windowHeight,
      transformCube
    } = this;

    switch (value) {
      case 2:
        animationClasses.main = ["cube_wrapper"];
        animationClasses.cube = ["bundle_cube", "bundle_cube_enter"];
        animationClasses.it = ["init_it", "init_it_enter"];
        animationClasses.content = ["init_content", "init_content_enter"];
        animationClasses.commercial = [
          "init_commercial",
          "init_commercial_enter"
        ];
        animationClasses.shadow = ["init_shadow_enter"];

        if (windowWidth > 960 && windowWidth < 1100) {
          transformCube.x = container.width / 2 - cube.width / 3.5;
          transformCube.y = 0;
          this.scaleCube = 1;
        } else if (windowWidth > 480 && windowWidth <= 960) {
          transformCube.x = container.width / 2 - cube.width / 2;
          transformCube.y = windowHeight / 2 - cube.width / 2;

          this.scaleCube = 1;
        } else if (windowWidth <= 480) {
          transformCube.x = container.width / 2 - cube.width / 2;
          transformCube.y = windowHeight / 2 - cube.width / 2;
          this.scaleCube = 0.6;
        } else {
          transformCube.x = container.width / 2 - cube.width / 2;
          transformCube.y = 0;

          this.scaleCube = 1;
        }
        this.emit("cubeRefresh", this.getterTransform);
        break;
      case 3:
        animationClasses.main = ["cube_wrapper"];
        animationClasses.it = [
          "init_it",
          "init_it_enter",
          "fade_in fade_in_enter"
        ];
        animationClasses.content = [
          "init_content",
          "init_content_enter",
          "fade_out fade_out_enter"
        ];
        animationClasses.commercial = [
          "init_commercial",
          "init_commercial_enter",
          "fade_out fade_out_enter"
        ];
        animationClasses.shadow = [
          "fade_out fade_out_enter",
          "init_shadow_enter"
        ];
        if (container.height < 700 && container.width > 480) {
          this.scaleCube = 1.3;
          console.log(container.height);
          transformCube.x = container.width / 2 - cube.width / 2;
          transformCube.y = 2 * container.height - (cube.width / 3) * 2;
          this.emit("cubeRefresh", this.getterTransform);
          break;
        }

        if (windowWidth > 480 && windowWidth <= 960) {
          transformCube.x = container.width / 2 - cube.width / 2;
          transformCube.y = 2 * container.height - cube.width / 2;

          this.scaleCube = 1.5;
        } else if (windowWidth <= 480) {
          this.scaleCube = 0.9;
          transformCube.x = container.width / 2 - cube.width / 2;
          transformCube.y = 2 * container.height - cube.width / 2;
        } else {
          this.scaleCube =
            Math.floor(windowWidth / container.width) === 1
              ? 1.5
              : Math.floor(windowWidth / container.width);
          transformCube.x = container.width / 2 - cube.width / 2;
          transformCube.y = 1.5 * container.height;
        }
        this.emit("cubeRefresh", this.getterTransform);
        break;

      case 4:
        animationClasses.main = ["cube_wrapper"];
        animationClasses.it = [
          "init_it",
          "init_it_enter",
          "fade_out fade_out_enter"
        ];
        animationClasses.content = [
          "init_content",
          "init_content_enter",
          "fade_in fade_in_enter"
        ];
        animationClasses.commercial = [
          "init_commercial",
          "init_commercial_enter",
          "fade_out fade_out_enter"
        ];

        if (windowWidth > 480 && windowWidth <= 960) {
          this.scaleCube = 1.5;
          transformCube.x = container.width / 1.5;
          transformCube.y = container.height * 2;
        } else if (windowWidth <= 480) {
          this.scaleCube = 0.9;
          transformCube.x = container.width / 4;
          transformCube.y =
            container.height *
            (container.height / container.width > 2
              ? 4 - container.height / container.width
              : container.height / container.width);
        } else {
          this.scaleCube =
            Math.floor(windowWidth / container.width) === 1
              ? 1.5
              : Math.floor(windowWidth / container.width);
          transformCube.x = container.width - cube.width;
          transformCube.y = 1.5 * container.height + cube.height / 2;
        }
        this.emit("cubeRefresh", this.getterTransform);
        break;
      case 5:
        animationClasses.main = ["cube_wrapper"];
        animationClasses.it = [
          "init_it",
          "init_it_enter",
          "fade_out fade_out_enter"
        ];
        animationClasses.content = [
          "init_content",
          "init_content_enter",
          "fade_out fade_out_enter"
        ];
        animationClasses.commercial = [
          "init_commercial",
          "init_commercial_enter",
          "fade_in fade_in_enter"
        ];
        if (windowWidth > 480 && windowWidth <= 960) {
          this.scaleCube = 1.5;
          transformCube.x = -cube.width / 2.5;
          transformCube.y = 2.5 * container.height + cube.height / 2;
        } else if (windowWidth <= 480) {
          this.scaleCube = 0.9;
          transformCube.x = -cube.width / 3;
          transformCube.y =
            container.height * 2.5 +
            cube.width /
              (container.height / container.width > 2
                ? 3.6 - container.height / container.width
                : 2);
        } else {
          this.scaleCube =
            Math.floor(windowWidth / container.width) === 1
              ? 1.5
              : Math.floor(windowWidth / container.width);
          transformCube.x = 0;
          transformCube.y = 2.5 * container.height + cube.height / 2;
        }
        this.emit("cubeRefresh", this.getterTransform);
        break;
      default:
        break;
    }
  }
}
