// Core
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
// Instruments
import "./index.scss";

export default class Icon extends Component {

  render() {
      const { name, isActive, delay, animation} = this.props;

    return (
     <div className={`icon_item ${animation} ${isActive}`}
      style ={{
          transitionDelay: `${delay}ms`, 
      }}>
        <div className={`icon_item_img ${name}`}></div>
        <FormattedMessage id={`icon.${name}`}>
            {txt => {
              return (
                <div className='icon_title'>{txt}</div>
              );
            }}
        </FormattedMessage>
        
     </div>
    );
  }
}