import React, { Component } from "react";
import { Menu, Sidebar, Divider } from "semantic-ui-react";
import { ClickMenuContext } from "../screens/Main/clickMenuContext";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../screens/App";
import { map } from "lodash";
import "./index.scss";

export default class MenuMobile extends Component {
  state = { activeItem: null };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  menu = ["Streams", "IT", "Content", "Commercial", "About_US", "Contact"];
  render() {
    const { activeItem } = this.state;
    const { isVisible } = this.props;
    return (
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          vertical
          direction="right"
          visible={isVisible}
          borderless={false}
          className="menu menu_mobile"
        >
          {map(this.menu, (el, index) => {
            return (
              <ClickMenuContext.Consumer key={index}>
                {({ handleClickMenu }) => (
                  <FormattedMessage id={`menu.${el}`}>
                    {txt => {
                      return (
                        <Menu.Item
                          name={txt}
                          active={activeItem === el}
                          onClick={() => handleClickMenu(index + 1)}
                        >
                          {txt}
                        </Menu.Item>
                      );
                    }}
                  </FormattedMessage>
                )}
              </ClickMenuContext.Consumer>
            );
          })}
          <Divider />
          <LanguageContext.Consumer>
            {({ changeLanguage, lang }) => (
              <Menu vertical>
                <Menu.Item
                  name="eng"
                  className={lang === "en" ? "language active" : "language"}
                  onClick={e => changeLanguage(e, { value: "en" })}
                >
                  EN
                </Menu.Item>

                <Menu.Item
                  name="ru"
                  className={lang === "ru" ? "language active" : "language"}
                  onClick={e => changeLanguage(e, { value: "ru" })}
                >
                  RU
                </Menu.Item>
                {
                  /*
                    <Menu.Item
                      name="deu"
                      className={lang === "de" ? "language active" : "language"}
                      onClick={e => changeLanguage(e, { value: "de" })}
                    >
                      DE
                    </Menu.Item>
                  */
                }
              </Menu>
            )}
          </LanguageContext.Consumer>
        </Sidebar>
        <Sidebar.Pusher style={{ minHeight: "100vh" }}>
          <div className="content_container">
            <div className="main_container">
              <FormattedMessage id={"main.h1"}>
                {txt => <h1 key={txt}>{txt}</h1>}
              </FormattedMessage>
              <FormattedMessage id={"main.text"}>
                {txt => {
                  return <div className="main_content">{txt}</div>;
                }}
              </FormattedMessage>
            </div>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
