import React, { Component } from "react";
import "./index.scss";
import HeaderETS from "../../components/headerETS";
import ContentBlock from "../../components/contentBlock";
import { FormattedMessage } from "react-intl";
import { PathItem } from "./components/Cube";
import { CubeContext } from "./components/CubeContext";
import CubeAnimation from "./components/cubeAnimation";
import ScrollAnimation from "react-animate-on-scroll";
import Cube from "../../assets/Cube.png";
import Cube_path from "../../assets/Cube_path.png";

import { isMobileContext } from "../App";

export default class Streams extends Component {
  constructor(props) {
    super(props);
    this.CubeAnimation = new CubeAnimation(this.state);
  }
  state = {
    page: 2,
    cube: { width: 0, height: 0 },
    container: { width: 0, height: 0 },
    windowWidth: 0,
    windowHeight: 0,
    transform: ""
  };

  _handleWindowResize = () => {
    if (!this.props.isMobile) {
      if (this._isMounted) {
        this.setState({
          ...this.state,
          container: {
            width: this._containerTarget.offsetWidth,
            height: this._containerTarget.offsetHeight
          },
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          cube: {
            width: this._cubeElem.offsetWidth,
            height: this._cubeElem.offsetHeight
          }
        });
        this.CubeAnimation.init({
          container: {
            width: this._containerTarget.offsetWidth,
            height: this._containerTarget.offsetHeight
          },
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          cube: {
            width: this._cubeElem.offsetWidth,
            height: this._cubeElem.offsetHeight
          }
        });
        this.CubeAnimation._currentPage = this.props.currentPage;
      }
    }
  };

  refreshCube = transform => {
    this.setState({ transform: transform });
  };

  componentDidMount() {
    this._isMounted = true;
    this.CubeAnimation.on("cubeRefresh", this.refreshCube);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.CubeAnimation._currentPage = props.currentPage;
    this.CubeAnimation.init(this.state);
  }

  getAnimatedContent = (txt, isActive) => {
    return (
      <isMobileContext.Consumer>
        {isMobile =>
          isMobile ? (
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <ContentBlock text={txt} />
            </ScrollAnimation>
          ) : (
            <ContentBlock className={`slide_up ${isActive}`} text={txt} />
          )
        }
      </isMobileContext.Consumer>
    );
  };

  render() {
    const { page, transform } = this.state;
    const { CubeAnimation } = this;
    const { isMobile } = this.props;

    let isActive;
    if (isMobile) {
      isActive = "active";
    } else {
      isActive = page === this.props.currentPage ? "active" : "";
    }
    const context = {
      ...this.state,
      transformCube: CubeAnimation.getterTransform,
      currentPage: this.props.currentPage
    };

    return (
      <div
        className="wrapper_container"
        ref={node => {
          // this callback executes before componentDidMount
          if (node !== null) {
            this._containerTarget = node;
            if (!this._isMounted) {
              this._isMounted = true;
              this._handleWindowResize();
            }
          }
        }}
      >
        <div className="content_container">
          <div className="main_container main_container_slide main_container_streams">
            <div className="content_wrapper">
              <FormattedMessage id={"Streams.h2"}>
                {txt => {
                  return <HeaderETS header={txt} />;
                }}
              </FormattedMessage>
              <FormattedMessage id={"Streams.text"}>
                {txt => this.getAnimatedContent(txt, isActive)}
              </FormattedMessage>
            </div>
            <isMobileContext.Consumer>
              {isMobile =>
                isMobile ? (
                  <div className="image_wrapper image_wrapper_cube">
                    <img src={Cube_path} className="cube_path" alt="cube" />
                    <ScrollAnimation
                      animateIn="fadeIn"
                      animateOnce={true}
                      delay={200}
                      duration={1.1}
                    >
                      <img src={Cube} className="cube" alt="cube" />
                    </ScrollAnimation>
                  </div>
                ) : null
              }
            </isMobileContext.Consumer>
          </div>
          <isMobileContext.Consumer>
            {isMobile =>
              !isMobile ? (
                <div
                  ref={node => {
                    // this callback executes before componentDidMount
                    if (node !== null) {
                      this._cubeElem = node;
                    }
                  }}
                  className={CubeAnimation.animationClasses.main.join(" ")}
                  style={{
                    transition: "transform 1000ms ease-in-out 0s",
                    transform: `translate3d(${transform}) scale(${CubeAnimation.scaleCube}) `
                  }}
                >
                  <div className="cube">
                    <CubeContext.Provider value={context}>
                      <PathItem
                        animationClasses={CubeAnimation.animationClasses}
                      />
                    </CubeContext.Provider>
                  </div>
                </div>
              ) : null
            }
          </isMobileContext.Consumer>
        </div>
      </div>
    );
  }
}
