import React, { useContext } from "react";
import { CubeContext } from "./CubeContext";
import { ReactComponent as Shadow } from "../../../assets/Shadow.svg";
export const ShadowItem = (props, ...args) => {
  const context = useContext(CubeContext);
  let shadowTransform = "0px, 0px, 0px";
  let opacity = 0;
  let rotate = "rotate(0)";
  let display = "block";
  const { currentPage } = context;
  switch (currentPage) {
    case 2:
      opacity = 1;
      rotate = "rotate(0)";
      break;
    case 3:
      shadowTransform = "0px, 0px, 0px";
      opacity = 1;
      rotate = "rotate(90deg)";
      display = "none";
      break;
    case (4, 5):
      display = "none";
      break;
    default:
      break;
  }
  return (
    <div
      style={{
        transition: "transform 1000ms ease-in-out 0ms",
        transform: `translate3d(${shadowTransform}) ${rotate}`,
        display: `${display}`,
        opacity: opacity
      }}
      className={`cube_item shadow_item init_shadow ${props.className.join(
        " "
      )}`}
    >
      <Shadow />
    </div>
  );
};
