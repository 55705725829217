import React, { Component } from "react";
import { Menu } from "semantic-ui-react";
import "./index.scss";
import { ClickMenuContext } from "../screens/Main/clickMenuContext";
import { FormattedMessage } from "react-intl";
import { map } from "lodash";
export default class MenuDesctop extends Component {
  state = { activeItem: null };
  menu = ["Streams", "IT", "Content", "Commercial", "About_US", "Contact"];
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <Menu className=" menu menu_desctop" borderless={false}>
        {map(this.menu, (el, index) => {
          return (
            <ClickMenuContext.Consumer key={index}>
              {({ handleClickMenu }) => (
                <FormattedMessage id={`menu.${el}`}>
                  {txt => {
                    return (
                      <Menu.Item
                        name={txt}
                        active={activeItem === el}
                        onClick={() => handleClickMenu(index + 1)}
                      >
                        {txt}
                      </Menu.Item>
                    );
                  }}
                </FormattedMessage>
              )}
            </ClickMenuContext.Consumer>
          );
        })}

        {/* <ClickMenuContext.Consumer >
        {({ handleClickMenu }) => (
          <Menu.Item
            name='IT'
            active={activeItem === 'IT'}
            onClick={()=>handleClickMenu(2)}
          />
          )}
          </ClickMenuContext.Consumer>
          <ClickMenuContext.Consumer >
        {({ handleClickMenu }) => (
          <Menu.Item
            name='Content'
            active={activeItem === 'Content'}
            onClick={()=>handleClickMenu(3)}
          />
          )}
          </ClickMenuContext.Consumer>
          <ClickMenuContext.Consumer >
        {({ handleClickMenu }) => (
          <Menu.Item
            name='Commercial'
            active={activeItem === 'Commercial'}
            onClick={()=>handleClickMenu(4)}
            />
            )}
            </ClickMenuContext.Consumer>
            <ClickMenuContext.Consumer >
        {({ handleClickMenu }) => (
          <Menu.Item
            name='About_US'
            active={activeItem === 'About_US'}
            onClick={()=>handleClickMenu(5)}
            />
            )}
            </ClickMenuContext.Consumer>
            <ClickMenuContext.Consumer >
        {({ handleClickMenu }) => (
          <Menu.Item
            name='Contact'
            active={activeItem === 'Contact'}
            onClick={()=>handleClickMenu(6)}
            />
            )}
            </ClickMenuContext.Consumer> */}
      </Menu>
    );
  }
}

// export default injectIntl(MenuDesctop)
