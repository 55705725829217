export const messages = {
  en: {
    "menu.Streams": "ECO-SYSTEM",
    "menu.IT": "IT PRODUCTS",
    "menu.Content": "MASSIVE CONTENT",
    "menu.Commercial": "COMMERCE",
    "menu.About_US": "ABOUT US",
    "menu.Contact": "CONTACTS",
    "menu.LogOut": "Logout",
    "main.h1":
      "ETS is an independent technology driven group, which delivers innovative travel solutions designed to boost and optimize your daily business.",
    "main.text":
      "Our intelligent products are intended for various travel industry players, including travel agencies, tour operators, corporations, aggregators and content providers.",
    "Streams.h2": "ECO-SYSTEM",
    "Streams.text":
      "We focus on three independent complementary business streams, which help us to provide state of art IT PRODUCTS with world-class MASSIVE CONTENT and additional COMMERCIAL interest behind.",
    "IT.h2": "IT PRODUCTS",
    "IT.text":
      "Our product line is designed to support your business goals and activities with the most efficient way. Please welcome bunch of technologies incorporated in 4 magnificent travel solutions.",
    "IT.Business.btn": "Business",
    "IT.Online.btn": "Online",
    "IT.Corporate.btn": "Corporate",
    "IT.MOS.btn": "MOS",
    "IT.Business.header": "Business",
    "IT.Online.header": "Online",
    "IT.Corporate.header": "Corporate",
    "IT.MOS.header": "MOS",
    "IT.Business.text":
      "Web based fully automated intelligent B2B core product created for demanding travel industry players. Consolidate, manage and distribute your content through own and sub-agent network in the most convenient and efficient way.",
    "IT.Online.text":
      "Boost your B2C sales with online productivity suite. Ready to use fully adaptive site, mobile app or API connectivity and back-office to maintain your digital expansion.",
    "IT.Corporate.text":
      "Next-generation online business trip management platform for enterprises and TMCs. Entirely focused on business travel experience it provides corporations full control in both travel and expense management.",
    "IT.MOS.text":
      "Incredibly powerful mid-office solution absorbs real time booking and sales data across all channels to manage your pre- and post-sales activities.",
    "learn more.btn": "LEARN MORE",
    "back.btn": "back",
    "IT.Business.slide1.header": "Сheck video for details",
    "IT.Business.slide2.header": "Title",
    "IT.Business.slide2.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.Online.slide1.header": "Title 222",
    "IT.Online.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.Corporate.slide1.header": "Title 3333",
    "IT.Corporate.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.MOS.slide1.header": "Title 444",
    "IT.MOS.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "Content.h2": "MASSIVE CONTENT",
    "Content.text":
      "Empower and manage your non-GDS content sales in one place under one single contract with ETS. Hotels, Flights, Rails and Cars multi-source content from numerous suppliers is consolidated and perfectly matched for the best commercial and user experience.",
    "Content.slide1.header": "WHY ETS | MASSIVE CONTENT?",
    "Content.slide1.list.item1":
      "direct & aggregated worldwide & local content",
    "Content.slide1.list.item2":
      "available through one single contract with ETS",
    "Content.slide1.list.item3":
      "under deposit line or instant credit card payments",
    "Content.slide1.list.item4":
      "in local currency, with no risks on exchange rates volatility",
    "Content.slide1.list.item5":
      "with build-in mark-up engine for generating your revenues",
    "Content.slide1.list.item6": "advanced reporting system",
    "Content.slide1.list.item7": "multilanguage interface",
    "Content.slide1.list.item8":
      "fully integrated into intelligent ETS IT PRODUCT line",
    "Commercial.h2": "COMMERCE",
    "Commercial.text":
      "Gain your commerce to the next level with ETS Smart Contracts. As a fundamental market leader, we achieved to get unique commercial deals with major travel players. Our «get and share» commercial model will grand you payouts just because you are our customer. So simple and so profitable.",
    "About_US.h2": "About us",
    "About_US.text":
      "ETS is a multi-international group of travel technology companies with a headquarter in Nuremberg (Germany). Sine 2007 our main goal was to deliver premium quality intelligent customer-oriented products. Today we count 5 representatives around the world, delivering tailor-made solutions to 43 markets. Our outstanding team of industry experts is excited to bring innovations and serve our travel-partners.",
    "Contact.h2": "Contacts",
    "Contact.sales.title": "Sales",
    "Contact.support.title": "Support and deployment",
    "Contact.mainoffice.title": "Headquarters",
    "Contact.mainoffice.company": "D-90402 Nuremberg, Germany,",
    "Contact.mainoffice.city": "Faerberstrasse 20.",
    "Contact.mainoffice.adress": "ETS International GmbH",
    "icon.web": "web",
    "icon.mobile": "mobile",
    "icon.api": "api",
    "icon.hotel": "hotels",
    "icon.flights": "flights",
    "icon.railway": "rails",
    "icon.car": "cars"
  },
  ru: {
    "menu.Streams": "Eco-system",
    "menu.IT": "IT Products",
    "menu.Content": "MASSIVE CONTENT",
    "menu.Commercial": "COMMERCE",
    "menu.About_US": "О нас",
    "menu.Contact": "Контакты",
    "menu.LogOut": "Logout",
    "main.h1":
      "ETS является независимой технологической группой компаний, которая предоставляет инновационные решения в сфере туризма, предназначенные для роста и оптимизации вашего бизнеса.",
    "main.text":
      "Наши интеллектуальные продукты предназначены для различных участников туристической индустрии, включая туристические агентства, авиакомпании, корпорации, туроператоров, агрегаторов и контент-провайдеров.",
    "Streams.h2": "ECO-SYSTEM",
    "Streams.text":
      "Мы сфокусированы на трех независимых взаимодополняющих бизнес направлениях, которые помогают создавать уникальные IT решения, включая предложения разнообразного туристического КОНТЕНТА с дополнительным КОММЕРЧЕСКИМ интересом для вас.",
    "IT.h2": "IT PRODUCTS",
    "IT.text":
      "Наша линейка продуктов спроектирована для того, чтобы максимально эффективно решат ваши бизнес-задачи и поддерживать ежедневные активности. Добро пожаловать в мир технологий, выраженный в четырех изумительных тревел решениях.",
    "IT.Business.btn": "Business",
    "IT.Online.btn": "Online",
    "IT.Corporate.btn": "Corporate",
    "IT.MOS.btn": "MOS",
    "IT.Business.header": "Business",
    "IT.Online.header": "Online",
    "IT.Corporate.header": "Corporate",
    "IT.MOS.header": "MOS",
    "IT.Business.text":
      "Полностью автоматизированное облачное B2B решение, спроектированное для требовательных игроков индустрии туризма. Консолидируйте, управляйте и распространяйте свой контент через собственную и субагентскую сеть наиболее удобным и эффективным способом.",
    "IT.Online.text":
      "Дайте толчок своим B2C продажам с помощью набора онлайн продуктов. Готовый к использованию полностью адаптивный сайт, мобильное приложение или API протокол и бэк-офис для управления вашей цифровой экспансией.",
    "IT.Corporate.text":
      "Платформа нового поколения по управлению деловыми поездками для предприятий и TMCs. Решение полностью сфокусированно на опыте деловых поездок и обеспечивает пользователям полный контроль над путешествиями и связанными с ними расходами.",
    "IT.MOS.text":
      "Невероятно мощное mid-офисное решение собирает данные о бронированиях и продажах в реальном времени, по всем каналам, для последующего управления пред- и пост- продажными процессами.",
    "learn more.btn": "Подробнее",
    "IT.Business.slide1.header": "Как это работает?",
    "IT.Business.slide2.header": "Title",
    "IT.Business.slide2.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.Online.slide1.header": "Title 222",
    "IT.Online.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.Corporate.slide1.header": "Title 3333",
    "IT.Corporate.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.MOS.slide1.header": "Title 444",
    "IT.MOS.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "Content.h2": "MASSIVE CONTENT",
    "Content.text":
      "Откройте границы и управляйте продажами не GDS контента в рамках единого контракта с ETS. Разнообразный контент многочисленных поставщиков отельного, авиационного, железнодорожного контента, а также трансферов, бережно собран для вас в едином решении, для упрощения поиска и дополнительной коммерческой выгоды.",
    "Content.slide1.header": "ПОЧЕМУ ETS | MASSIVE CONTENT?",
    "Content.slide1.list.item1":
      "прямой и агрегированный, международный и локальный контент",
    "Content.slide1.list.item2":
      "доступный по единому контракту с ETS",
    "Content.slide1.list.item3":
      "в рамках единого депозита или с прямой оплатой платежными картами",
    "Content.slide1.list.item4":
      "в локальной валюте, без риска курсовых колебаний ",
    "Content.slide1.list.item5":
      "со встроенным модулем ценообразования для генерации доходов",
    "Content.slide1.list.item6": "современной системой отчетности",
    "Content.slide1.list.item7": "мультиязычным интерфейсом",
    "Content.slide1.list.item8":
      "полностью интегрировано линейку IT продуктов ETS",
    "Commercial.h2": "COMMERCE",
    "Commercial.text":
      "Поднимите свою коммерцию на качественно новый уровень с ETS Smart Contracts. Как системообразующему лидеру рынка, нам удалось заключить уникальные коммерческие договора с крупными туристическими игроками. Наша коммерческая модель «get and share» гарантирует вам больше выгоды только потому, что вы являетесь нашим клиентом. Так просто и так выгодно.",
    "About_US.h2": "О нас",
    "About_US.text":
      "ETS является международной группой компаний, занимающихся технологиями в сфере туризма, со штаб-квартирой в городе Нюрнберг, Германия. Начиная с 2007 года, наша главная цель – предлагать высокотехнологичные уникальные продукты, в соответствии с ожиданиями наших клиентов. Сегодня мы насчитываем 5 представительств по всему миру, поставляя локализированные решения для 43 рынков. Наша невероятная команда отраслевых экспертов стоит на страже инноваций для предоставления высокого уровня сервиса нашим партерам.",
    "Contact.h2": "Контакты",
    "Contact.sales.title": "Продажи",
    "Contact.support.title": "Поддержка и внедрение",
    "Contact.mainoffice.title": "Главный офис",
    "Contact.mainoffice.company": "D-90402 Nuremberg, Germany,",
    "Contact.mainoffice.city": "Faerberstrasse 20.",
    "Contact.mainoffice.adress": "ETS International GmbH",
    "icon.web": "web",
    "icon.mobile": "mobile",
    "icon.api": "api",
    "icon.hotel": "hotel",
    "icon.flights": "flights",
    "icon.railway": "railway",
    "icon.car": "car",
    "back.btn": "назад"
  },
  de: {
    "menu.Streams": "ECO-SYSTEM",
    "menu.IT": "IT PRODUCTS",
    "menu.Content": "MASSIVE CONTENT",
    "menu.Commercial": "COMMERCE",
    "menu.About_US": "ABOUT US",
    "menu.Contact": "CONTACTS",
    "menu.LogOut": "Logout",
    "main.h1":
      "ETS is an independent technology driven group, which delivers innovative travel solutions designed to boost and optimize your daily business.",
    "main.text":
      "Our intelligent products are intended for various travel industry players, including travel agencies, tour operators, corporations, aggregators and content providers.",
    "Streams.h2": "ECO-SYSTEM",
    "Streams.text":
      "We focus on three independent complementary business steams, which help us to provide state of art IT PRODUCTS with world-class MASSIVE CONTENT and additional COMMERCIAL interest behind.",
    "IT.h2": "IT PRODUCTS",
    "IT.text":
      "Our product line is designed to support your business goals and activities with the most efficient way. Please welcome bunch of technologies incorporated in 4 magnificent travel solutions.",
    "IT.Business.btn": "Business",
    "IT.Online.btn": "Online",
    "IT.Corporate.btn": "Corporate",
    "IT.MOS.btn": "MOS",
    "IT.Business.header": "Business",
    "IT.Online.header": "Online",
    "IT.Corporate.header": "Corporate",
    "IT.MOS.header": "MOS",
    "IT.Business.text":
      "Web based fully automated intelligent B2B core product created for demanding travel industry players. Consolidate, manage and distribute your content through own and sub-agent network in the most convenient and efficient way.",
    "IT.Online.text":
      "Boost your B2C sales with online productivity suite. Ready to use fully adoptive site, mobile app or API connectivity and back-office to maintain your digital expansion.",
    "IT.Corporate.text":
      "Next-generation online business trip management platform for enterprises and TMCs. Entirely focused on business travel experience it provides corporations full control in both travel and expense management.",
    "IT.MOS.text":
      "Incredibly powerful mid-office solution absorbs real time booking and sales data across all channels to manage your pre- and post-sales activities.",
    "learn more.btn": "LEARN MORE",
    "IT.Business.slide1.header": "How it works?",
    "IT.Business.slide2.header": "Title",
    "IT.Business.slide2.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.Online.slide1.header": "Title 222",
    "IT.Online.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.Corporate.slide1.header": "Title 3333",
    "IT.Corporate.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "IT.MOS.slide1.header": "Title 444",
    "IT.MOS.slide1.text":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget.",
    "Content.h2": "MASSIVE CONTENT",
    "Content.text":
      "Empower and manage your non-GDS content sales in one place under one single contract with ETS. Hotels, Flights, Rails and Cars multi-source content from numerous suppliers is consolidated and perfectly matched for the best commercial and user experience.",
    "Content.slide1.header": "WHY ETS | MASSIVE CONTENT?",
    "Content.slide1.list.item1":
      "direct & aggregated worldwide & local content",
    "Content.slide1.list.item2":
      "available through one single contract with ETS",
    "Content.slide1.list.item3":
      "under deposit line or instant credit card payments",
    "Content.slide1.list.item4":
      "in local currency, with no risks on exchange rates volatility",
    "Content.slide1.list.item5":
      "with build-in mark-up engine for generating your revenues",
    "Content.slide1.list.item6": "advanced reporting system",
    "Content.slide1.list.item7": "multilanguage interface ",
    "Content.slide1.list.item8":
      "fully integrated into intelligent ETS IT PRODUCT line",
    "Commercial.h2": "COMMERCE",
    "Commercial.text":
      "Gain your commerce to the next level with ETS Smart Contracts. As a fundamental market leader, we achieved to get unique commercial deals with major travel players. Our «get and share» commercial model will grand you payouts just because you are our customer. So simple and so profitable.",
    "About_US.h2": "About us",
    "About_US.text":
      "ETS is a multi-international group of travel technology companies with a headquarter in Nuremberg (Germany). Sine 2007 our main goal was to deliver premium quality intelligent customer-oriented products. Today we count 5 representatives around the world, delivering tailor-made solutions to 43 markets. Our outstanding team of industry experts is excited to bring innovations and serve our travel-partners.",
    "Contact.h2": "Contacts",
    "Contact.sales.title": "Sales",
    "Contact.support.title": "Support and deployment",
    "Contact.mainoffice.title": "Headquarters",
    "Contact.mainoffice.company": "D-90402 Nuremberg, Germany,",
    "Contact.mainoffice.city": "Faerberstrasse 20.",
    "Contact.mainoffice.adress": "ETS International GmbH",
    "icon.web": "web",
    "icon.mobile": "mobile",
    "icon.api": "api",
    "icon.hotel": "hotel",
    "icon.flights": "flights",
    "icon.railway": "railway",
    "icon.car": "car",
    "back.btn": "назад"
  }
};
