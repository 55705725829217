import React from "react";
import { Dropdown } from "semantic-ui-react";
import { LanguageContext } from "../screens/App";
const langs = [
  {
    key: "en",
    text: "EN",
    value: "en"
  },
  {
    key: "ru",
    text: "RU",
    value: "ru"
  }/*,
  {
    key: "de",
    text: "DE",
    value: "de"
  }*/
];

const LanguageDropDown = props => {
  return (
    <LanguageContext.Consumer>
      {({ changeLanguage, lang }) => (
        <Dropdown
          button
          value={lang}
          options={langs}
          onChange={changeLanguage}
          icon={false}
        />
      )}
    </LanguageContext.Consumer>
  );
};

export default LanguageDropDown;
