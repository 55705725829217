import React from "react";
import { ReactComponent as Path } from "../../../assets/Path.svg";

import { ItItem } from "./ItItem";
import { ContentItem } from "./ContentItem";
import { CommercialItem } from "./CommercialItem";
import { ShadowItem } from "./ShadowItem";
export const PathItem = props => {
  const { animationClasses } = props;

  return (
    <div className={animationClasses.cube.join(" ")}>
      <Path />

      <ItItem className={animationClasses.it} />

      <ContentItem className={animationClasses.content} />

      <CommercialItem className={animationClasses.commercial} />

      <ShadowItem className={animationClasses.shadow} />
    </div>
  );
};
