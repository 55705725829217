import React, { Component } from 'react'
import './index.scss';
import ReactPlayer from 'react-player'

export default class Video extends Component {
    render () {
        const { url, poster } = this.props
        console.log(url)
        console.log(poster)
        return (
            <div className="player_wrapper">
                <ReactPlayer 
                        className='react_player' 
                        url={url} 
                        controls 
                        width='100%' 
                        height='100%'
                        config={{ file: { attributes: { disablePictureInPicture: true, controlsList: 'nodownload',poster:poster }}}}
                        />
            </div>
        )
       
      }
}