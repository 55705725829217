import React, { Component } from "react";
import { Responsive } from "semantic-ui-react";
import ScrollAnimation from "react-animate-on-scroll";
import "./index.scss";
import MenuDesctop from "../../components/menuDesctop";
import MenuMobile from "../../components/menuMobile";
import HeaderBtn from "../../components/headerBtn";
import LanguageDropDown from "../../components/languageDropDown";
import logo from "../../assets/img/logo.svg";
import { Logo } from "./components/logo";
import { FormattedMessage } from "react-intl";

//functional Components
import { MainText } from "./components/mainText.js";

export default class Main extends Component {
  state = { activeBtn: false, page: 1, isActive: "" };

  _handleBtnClick = () => {
    const currentState = this.state.activeBtn;
    this.setState({ ...this.state, activeBtn: !currentState });
  };
  UNSAFE_componentWillReceiveProps(props) {
    props.currentPage !== 1 && this.setState({ activeBtn: false });
  }

  render() {
    const { activeBtn, isActive } = this.state;

    return (
      <div id="Main" className="wrapper_container">
        <ScrollAnimation animateIn="fadeInDown" offset={0} animateOnce={true}>
          <header className={`header_container`}>
            <Logo logo={logo} />

            <Responsive
              className={`menu_container`}
              minWidth={1024}
              fireOnMount={true}
            >
              <MenuDesctop />
              <LanguageDropDown />
            </Responsive>

            <Responsive
              className="header_btn_container"
              maxWidth={1023}
              fireOnMount={true}
            >
              <HeaderBtn
                activeBtn={activeBtn}
                _handleBtnClick={this._handleBtnClick}
              />
            </Responsive>
          </header>
        </ScrollAnimation>
        <Responsive maxWidth={1023}>
          <MenuMobile isVisible={activeBtn} />
        </Responsive>

        <MainText isActive={isActive}></MainText>
        <Responsive
          className="content_container content_container_main"
          minWidth={1024}
        >
          <div className="main_container">
            <FormattedMessage id={"main.h1"}>
              {txt => {
                return <h1 className={`fade_in ${isActive}`}>{txt}</h1>;
              }}
            </FormattedMessage>
            <FormattedMessage id={"main.text"}>
              {txt => {
                return (
                  <div
                    className={`main_content fade_in transition_delay  ${isActive}`}
                  >
                    {txt}
                  </div>
                );
              }}
            </FormattedMessage>
          </div>
        </Responsive>
      </div>
    );
  }
}
