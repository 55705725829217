// Core
import React, { Component } from 'react';

// Instruments
import './index.scss';

export default class HeaderETS extends Component {
    render () {
        const {header} =  this.props;
        return  (
           <h2>
            <div className="header_ets">ETS</div>
            <div className="header_content">{header}</div>
          </h2>
        );
    }
}
