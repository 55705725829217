import React from "react";

import { ReactComponent as IT } from "../../../assets/IT.svg";
export const ItItem = props => {
  return (
    <div className={`cube_item it_item ${props.className.join(" ")}`}>
      <IT />
    </div>
  );
};
