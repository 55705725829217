import React, { Component } from "react";
import "./index.scss";
import ContentBlock from "../../components/contentBlock";
import { FormattedMessage } from "react-intl";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobileContext } from "../App";
export default class AboutUs extends Component {
  state = { page: 6 };

  getAnimatedContent = (txt, isActive) => {
    return (
      <isMobileContext.Consumer>
        {isMobile =>
          isMobile ? (
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <ContentBlock className={`slide_up active`} text={txt} />
            </ScrollAnimation>
          ) : (
            <ContentBlock className={`slide_up ${isActive}`} text={txt} />
          )
        }
      </isMobileContext.Consumer>
    );
  };
  render() {
    const { page } = this.state;
    const isActive = page === this.props.currentPage ? "active" : "";
    return (
      <div className="wrapper_container">
        <div className="content_container about_us">
          <div className="main_container main_container_slide about_us">
            <div className="content_wrapper">
              <FormattedMessage id={"About_US.h2"}>
                {txt => {
                  return <h2>{txt}</h2>;
                }}
              </FormattedMessage>
              <FormattedMessage id={"About_US.text"}>
                {txt => this.getAnimatedContent(txt, isActive)}
              </FormattedMessage>
            </div>
            <isMobileContext.Consumer>
              {isMobile =>
                isMobile ? (
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    delay={200}
                  >
                    <div className={`image_wrapper slide_right active`}>
                      <div className="image_container"></div>
                    </div>
                  </ScrollAnimation>
                ) : (
                  <div className={`image_wrapper slide_right ${isActive}`}>
                    <div className="image_container"></div>
                  </div>
                )
              }
            </isMobileContext.Consumer>
          </div>
        </div>
      </div>
    );
  }
}
