// Core
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

// Instruments
import "./index.scss";

export default class List extends Component {

  render() {
      const { items} = this.props;
      let itemsJSX = items.map((item, i) => {
          return  (
            <FormattedMessage key = {item} id={`Content.slide1.list.${item}`}>
            {txt => {
              return (
                <li > {txt}</li>
              );
            }}
          </FormattedMessage>
          )
      })
    return (
      <ul className="content_block">
      {itemsJSX}
      </ul>
    );
  }
}