// Core
import React, { Component } from "react";
import HeaderETS from "./headerETS";
import ContentBlock from "./contentBlock";
import MoreBtn from "./moreBtn";
import IconsBlock from "./iconsBlock";
import { FormattedMessage } from "react-intl";
import Close from "./close";
import { isMobileContext } from "../screens/App";
// Instruments
import "./index.scss";

export default class PopupSlide extends Component {
  state = { value: true };
  render() {
    const {
      name,
      header,
      text,
      isOpen,
      positionLeft,
      positionTop,
      icons,
      onClickFunc,
      isSliderVisible,
      isClick,
      closeFunc,
      anotherDelayToBlue
    } = this.props;
    const isActive = isOpen ? "active" : "";
    const slideLeft = isOpen && isSliderVisible ? "slide_left_slide" : "";
    const delay = isClick ? "delay" : "";
    const isBlue = name === "Business" && !anotherDelayToBlue ? "isBlue" : "";
    
    return (
      <isMobileContext.Consumer>
        {isMobile => (
          <div
            className={`content_container popupSlide ${
              isMobile ? "mobile" : ""
            }  ${name} ${isActive} ${slideLeft} ${delay} ${isBlue}`}
            style={{
              top: `${positionTop}px`,
              left: `${positionLeft}px`
            }}
          >
            <div className="popup_wrapper">
              <Close onClick={closeFunc} />
              <div className="main_container main_container_popup">
                <div className="content_wrapper">
                  <FormattedMessage id={header}>
                    {txt => {
                      return <HeaderETS header={txt} />;
                    }}
                  </FormattedMessage>
                  <FormattedMessage id={text}>
                    {txt => {
                      return (
                        <ContentBlock
                          className={`slide_left ${isActive}`}
                          text={txt}
                        />
                      );
                    }}
                  </FormattedMessage>

                  {icons && (
                    <IconsBlock
                      icons={icons}
                      isActive={isActive}
                      animation="slide_left_icons"
                    />
                  )}
                  <FormattedMessage id={"learn more.btn"}>
                    {txt => {
                      if(name=="Business")
                      return (
                        <MoreBtn text={txt} name={name} onClick={onClickFunc} />
                      );
                    }}
                  </FormattedMessage>
                </div>
                <div className="image_wrapper">
                  <div className="image_container"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </isMobileContext.Consumer>
    );
  }
}
