import React, { Component } from 'react'
import './index.scss';

export default class HeaderBtn extends Component {
  render() {
    const {activeBtn, _handleBtnClick} = this.props;
    return (
        <div className = {activeBtn ? 'header_btn active': 'header_btn' } 
             onClick = { _handleBtnClick } >
            <div className="header_btn_inner">
                <span></span>
            </div>
        </div>
       
    )
  }
}