import React from "react";

import { ReactComponent as Commercial } from "../../../assets/Commercial.svg";
export const CommercialItem = props => {
  return (
    <div className={`cube_item commercial_item ${props.className.join(" ")}`}>
      <Commercial />
    </div>
  );
};
