import React, { Component } from "react";
import "./index.scss";
import HeaderETS from "../../components/headerETS";
import ContentBlock from "../../components/contentBlock";
import { FormattedMessage } from "react-intl";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobileContext } from "../App";
import Commercial_img from "../../assets/Commercial.png";
import Commercial_path from "../../assets/Commercial_path.png";

export default class Comercial extends Component {
  state = { page: 5 };

  getAnimatedContent = (txt, isActive) => {
    return (
      <isMobileContext.Consumer>
        {isMobile =>
          isMobile ? (
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <ContentBlock className={`slide_up active`} text={txt} />
            </ScrollAnimation>
          ) : (
            <ContentBlock className={`slide_up ${isActive}`} text={txt} />
          )
        }
      </isMobileContext.Consumer>
    );
  };

  render() {
    const { page } = this.state;
    const isActive = page === this.props.currentPage ? "active" : "";
    return (
      <div className="wrapper_container">
        <div className="content_container">
          <div className="main_container main_container_slide right">
            <isMobileContext.Consumer>
              {isMobile =>
                isMobile ? (
                  <div className="image_wrapper image_wrapper_commercial">
                    <img
                      src={Commercial_path}
                      className="commercial_path"
                      alt="commercial"
                    />

                    <ScrollAnimation
                      animateIn="fadeIn"
                      animateOnce={true}
                      delay={200}
                      duration={1.1}
                    >
                      <img
                        src={Commercial_img}
                        className="commercial_img"
                        alt="commercial"
                      />
                    </ScrollAnimation>
                  </div>
                ) : null
              }
            </isMobileContext.Consumer>
            <div className="content_wrapper">
              <FormattedMessage id={"Commercial.h2"}>
                {txt => {
                  return <HeaderETS header={txt} />;
                }}
              </FormattedMessage>

              <FormattedMessage id={"Commercial.text"}>
                {txt => this.getAnimatedContent(txt, isActive)}
              </FormattedMessage>
            </div>
            <div className="image_wrapper"></div>
          </div>
        </div>
      </div>
    );
  }
}
