// Core
import React, { Component } from "react";

// Instruments
import "./index.scss";

export default class MoreBtn extends Component {
  render() {
    const { text, name, onClick, isActive} = this.props;
    return (
        <div className={`more_btn ${isActive ? isActive : ''}`}
            name ={name}
             onClick= {() => onClick(name)}>
          <div className="more_btn_text">{text}</div>
          <div className="more_btn_img"></div>
        </div>
    );
  }
}
